import React, { useState } from "react";
import { graphql } from "gatsby";
import Layout from "../layout";
import SEO from "../components/seo";
import styled from "styled-components";
import {
  MainTitle,
  InnerWrapper,
  darkBlue,
  lightGrey,
  mainGrey,
  mainWhite,
  screen,
} from "../components/common/variables";
import { GatsbyImage } from "gatsby-plugin-image";
import Collapsible from "react-collapsible";
import { FaChevronRight, FaChevronDown } from "react-icons/fa";

const Wrapper = styled.div`
  padding-top: 0;
  background-color: ${mainWhite};
  @media ${screen.medium} {
    padding-top: 70px;
  }

  .selected-testimonial {
    display: flex;
    padding-top: 30px;
    flex-wrap: wrap;
    padding-bottom: 10px;
    @media ${screen.xSmall} {
      padding-top: 45px;
      flex-wrap: nowrap;
      padding-bottom: 0;
      display: none;
    }
    @media ${screen.medium} {
      display: flex;
    }
    @media ${screen.large} {
      padding-top: 90px;
    }

    .title {
      margin-bottom: 15px;
      color: ${darkBlue};
      line-height: 1.15;
      @media ${screen.small} {
        margin-bottom: 20px;
      }
    }
    &__img {
      margin: 12px auto 0 auto;
      order: 1;
      max-width: 410px;
      width: 100%;
      @media ${screen.xSmall} {
        margin: 0;
        order: 0;
        width: 465px;
      }
    }
    &__texts {
      max-width: 660px;
      align-self: center;
      margin-left: 0;
      @media ${screen.xSmall} {
        margin-left: 45px;
      }
      @media ${screen.medium} {
        margin-left: 60px;
      }
      @media ${screen.large} {
        margin-left: 160px;
      }
    }

    &__msg {
      position: relative;
      p {
        color: ${darkBlue};
        font-size: 1rem;
        margin-bottom: 12px;
        @media ${screen.medium} {
          margin-bottom: 36px;
        }
        @media ${screen.large} {
          font-size: 1.1rem;
        }

        &:first-child {
          &:before {
            content: open-quote;
            font-size: 1.1rem;
          }
        }
        &:last-child {
          &:after {
            content: close-quote;
            font-size: 1.1rem;
          }
        }
      }
    }

    &__name {
      color: ${darkBlue};
      font-size: 1rem;
    }

    &__company {
      color: ${darkBlue};
      text-transform: uppercase;
      font-weight: 300;
      font-size: 1rem;
      margin-top: 3px;
    }
  }

  .testimonial-list {
    margin-top: 90px;
    padding-bottom: 90px;
    display: none;
    @media ${screen.xSmall} {
      display: block;
      margin-top: 0;
    }
    @media ${screen.medium} {
      margin-top: 90px;
    }
    &__each-testimonial {
      display: flex;
      justify-content: space-between;
      border-top: 2px solid ${mainGrey};
      padding-top: 60px;
      padding-bottom: 60px;
    }
    &__img {
      width: 260px;
    }

    &__texts {
      max-width: 900px;
      margin-left: 30px;
      color: ${darkBlue};
    }

    &__msg {
      p {
        margin-bottom: 36px;
        font-size: 1.1rem;
        &:first-child {
          &:before {
            content: open-quote;
            font-size: 1.1rem;
          }
        }
        &:last-child {
          &:after {
            content: close-quote;
            font-size: 1.1rem;
          }
        }
      }
    }

    &__name {
      color: ${darkBlue};
      font-size: 1rem;
    }

    &__company {
      text-transform: uppercase;
      font-weight: 300;
      font-size: 1rem;
      margin-top: 3px;
      line-height: 1.3;
    }

    &--mobile {
      margin-top: 20px;
      padding-bottom: 45px;
      @media ${screen.xSmall} {
        display: none;
      }
      .Collapsible {
        border-top: 2px ${lightGrey} solid;
        position: relative;
        padding: 12px 0 12px 0;

        h3 {
          color: ${darkBlue};
          max-width: 231px;
          cursor: pointer;
          svg {
            color: ${darkBlue};
            position: absolute;
            right: 10px;
            top: 16px;
            font-size: 1.1rem;
          }
        }

        &__contentInner {
          p {
            color: ${darkBlue};
            font-size: 1rem;
            margin-top: 5px;
            padding-right: 20px;
            &:first-child {
              &:before {
                content: open-quote;
                font-size: 1.1rem;
              }
            }
            &:last-child {
              &:after {
                content: close-quote;
                font-size: 1.1rem;
              }
            }
          }
        }

        .name {
          display: table;
          h3 {
            color: ${darkBlue};
            font-size: 1rem;
            display: table;
          }
        }

        .company {
          color: ${darkBlue};
          font-size: 1rem;
          font-weight: 350;
          padding-right: 40px;
        }

        .photo {
          width: 100%;
          margin: 0 auto;
          margin-top: 20px;
          padding-bottom: 20px;
        }
      }

      div:last-child .Collapsible {
        border-bottom: 2px ${lightGrey} solid;
      }
    }

    &__top-story-on-tablet {
      display: none;

      .title {
        padding-top: 30px;
        margin-bottom: -35px;
        color: ${darkBlue};
      }
      article {
        border-top: none;
      }
      @media ${screen.xSmall} {
        display: block;
      }
      @media ${screen.medium} {
        display: none;
      }
    }
  }
`;

const SuccessStoriesPage = (props) => {
  const titleTag = props.data.seo.data.title_tag;
  const metaDescription = props.data.seo.data.meta_description;
  // To be featured testimonial based on testimonial ID. If the testimonial is navigated from the carousel it will pass a prop ID and use that received prop ID to be set as the featured testimonial.
  // If no prop ID has been passed just set the featured testimonial from the latest one.
  const testimonialId =
    props.location.state === null
      ? props.data.testimonials.edges[0].node.id
      : props.location.state &&
        typeof props.location.state.selectedId !== "undefined"
      ? props.location.state.selectedId
      : props.data.testimonials.edges[0].node.id;

  // Filter testimonilas to be the featured testimonial based on ID.
  const featuredTestimonial = props.data.testimonials.edges.filter(
    (testimonial) => testimonialId === testimonial.node.id
  );

  // All testimonials just the featured testimonial is filtered out so no duplication.
  const testimonials = props.data.testimonials.edges.filter(
    (testimonial) => testimonial.node.id !== testimonialId
  );

  return (
    <Layout>
      <SEO title={titleTag} description={metaDescription} />
      <Wrapper>
        <InnerWrapper>
          <main className="selected-testimonial">
            <div className="selected-testimonial__img">
              <GatsbyImage
                image={featuredTestimonial[0].node.data.photo.gatsbyImageData}
                alt="Global Adviser Alpha success story"
              />
            </div>
            <div className="selected-testimonial__texts">
              <MainTitle className="title">Success Stories</MainTitle>
              <div
                className="selected-testimonial__msg"
                dangerouslySetInnerHTML={{
                  __html: featuredTestimonial[0].node.data.message.html,
                }}
              />

              <h3 className="selected-testimonial__name">
                {featuredTestimonial[0].node.data.full_name.text}
              </h3>
              <p className="selected-testimonial__company">
                {featuredTestimonial[0].node.data.company.text}
                {featuredTestimonial[0].node.data.occupation_title.text &&
                  ` - ${featuredTestimonial[0].node.data.occupation_title.text}`}
              </p>
            </div>
          </main>

          <div className="testimonial-list">
            <div className="testimonial-list__top-story-on-tablet">
              <MainTitle className="title">Success Stories</MainTitle>
              <article className="testimonial-list__each-testimonial">
                <div className="testimonial-list__img">
                  <GatsbyImage
                    image={
                      featuredTestimonial[0].node.data.photo.gatsbyImageData
                    }
                    alt={`Global Alpha Adviser - ${featuredTestimonial[0].node.data.full_name.text}`}
                  />
                </div>
                <div className="testimonial-list__texts">
                  <div
                    className="testimonial-list__msg"
                    dangerouslySetInnerHTML={{
                      __html: `${featuredTestimonial[0].node.data.message.html}`,
                    }}
                  />
                  <h3 className="testimonial-list__name">
                    {featuredTestimonial[0].node.data.full_name.text}
                  </h3>

                  <p className="testimonial-list__company">
                    {featuredTestimonial[0].node.data.company.text} -{" "}
                    {featuredTestimonial[0].node.data.occupation_title.text}
                  </p>
                </div>
              </article>
            </div>

            {testimonials.map((testimonial) => {
              return (
                <article
                  className="testimonial-list__each-testimonial"
                  key={testimonial.node.id}
                >
                  <div className="testimonial-list__img">
                    <GatsbyImage
                      image={testimonial.node.data.photo.gatsbyImageData}
                      alt={`Global Alpha Adviser - ${testimonial.node.data.full_name.text}`}
                    />
                  </div>
                  <div className="testimonial-list__texts">
                    <div
                      className="testimonial-list__msg"
                      dangerouslySetInnerHTML={{
                        __html: `${testimonial.node.data.message.html}`,
                      }}
                    />
                    <h3 className="testimonial-list__name">
                      {testimonial.node.data.full_name.text}
                    </h3>

                    <p className="testimonial-list__company">
                      {testimonial.node.data.company.text}
                      {testimonial.node.data.occupation_title.text &&
                        ` - ${testimonial.node.data.occupation_title.text}`}
                    </p>
                  </div>
                </article>
              );
            })}
          </div>
          <div className="testimonial-list--mobile">
            {testimonials.map((testimonial, i) => (
              <Collapsible
                key={i}
                triggerWhenOpen={
                  <div>
                    <h3>
                      {testimonial.node.data.full_name.text}
                      <FaChevronDown />
                    </h3>

                    <p className="company">
                      {testimonial.node.data.company.text}
                      {testimonial.node.data.occupation_title.text &&
                        ` - ${testimonial.node.data.occupation_title.text}`}
                    </p>
                  </div>
                }
                trigger={
                  <div>
                    <h3>
                      {testimonial.node.data.full_name.text}
                      <FaChevronRight />
                    </h3>

                    <p className="company">
                      {testimonial.node.data.company.text}
                      {testimonial.node.data.occupation_title.text &&
                        ` - ${testimonial.node.data.occupation_title.text}`}
                    </p>
                  </div>
                }
                transitionTime={150}
              >
                <div
                  className="message"
                  dangerouslySetInnerHTML={{
                    __html: `${testimonial.node.data.message.html}`,
                  }}
                />
                <div className="photo">
                  <GatsbyImage
                    image={testimonial.node.data.photo.gatsbyImageData}
                    alt="featured image"
                  />
                </div>
              </Collapsible>
            ))}
          </div>
        </InnerWrapper>
      </Wrapper>
    </Layout>
  );
};

export default SuccessStoriesPage;

export const data = graphql`
  {
    testimonials: allPrismicTestimonial(
      sort: { fields: data___edit_published_date, order: DESC }
    ) {
      edges {
        node {
          id
          data {
            full_name {
              text
            }
            company {
              text
            }
            occupation_title {
              text
            }
            photo {
              alt
              gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
            }
            message {
              html
            }
          }
        }
      }
    }
    seo: prismicSuccessStoriesPage {
      data {
        title_tag
        meta_description
      }
    }
  }
`;
